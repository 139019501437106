import React from "react"
import Layout from "hoc/Layout"
import Header from "containers/Header/Header"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Button from "../components/Buttons/Button"
import WhyTrustUs from "../containers/ForClients/WhyTrustUs/WhyTrustUs"
import MediraInNumbers from "../containers/ForClients/MediraInNumbers/MediraInNumbers"
import OurServices from "../containers/ForClients/OurServices/OurServices"
import Benefits from "../containers/ForClients/Benefits/Benefits"
import HomeCareVSHouseCare from "../containers/ForClients/HomeCareVSHouseCare/HomeCareVSHouseCare"
import RecrutationSteps from "../containers/ForClients/RecrutationsSteps/RecrutationSteps"
import Opinions from "../containers/ForClients/Opinions/Opinions"
import FAQSection from "../containers/ForClients/FAQ/FAQSection"
import ContactForm from "../containers/ForClients/ContactForm/ContactForm"
import ClientApplicationFormModal from "../components/ClientAppliccationFormModal/ClientApplicationFormModal"

const ForClients = () => {
  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    "Dla Klienta",
  ]
  const { forClientMainPhoto } = useStaticQuery(
    graphql`
      query {
        forClientMainPhoto: file(name: { eq: "for-client-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 90
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(forClientMainPhoto)
  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle="Dla Klienta">
      <Header
        title={"Dla Klienta"}
        titleDescription={
          "Profesjonalna opieka domowa nad seniorami.\nZaopiekujemy się Twoim bliskim w jego domu."
        }
        mainImage={mainImage}
        mainImageAlt={"Dla Klienta"}
        button={
          <Button
            sx={{ marginTop: { xs: "20px", md: "40px" } }}
            onClick={() => {
              document
                .getElementById("kontakt")
                .scrollIntoView({ behavior: "smooth" })
            }}
          >
            Zamów opiekę domową
          </Button>
        }
      />
      <WhyTrustUs />
      <MediraInNumbers />
      <OurServices />
      <Benefits />
      <HomeCareVSHouseCare />
      <RecrutationSteps />
      <Opinions />
      <FAQSection />
      <ContactForm />
      <ClientApplicationFormModal />
    </Layout>
  )
}

export default ForClients
