import { Grid, Tab, Tabs } from "@mui/material"
import styled from "styled-components"
import { theme } from "theme/theme"

export const Wrapper = styled(Grid)`
  && {
    box-shadow: ${theme.shadows.primary};
    border-radius: 10px;
    overflow: hidden;
  }
`

export const StyledTab = styled(Tab)`
  && {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: ${({ amount }) => `${100 / amount}%`};

    min-width: auto;
    color: #988fb6;
    opacity: 0.65;

    &.Mui-selected {
      position: relative;
      background-color: white;
      overflow: visible;
      opacity: 1;

      .MuiTouchRipple-root {
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 10px;
        height: 10px;
        z-index: 1;
      }
      &::before {
        left: -10px;
        background: radial-gradient(
          circle at left top,
          transparent 70%,
          rgb(255, 255, 255) 69%
        );
      }
      &::after {
        right: -10px;
        background: radial-gradient(
          circle at right top,
          transparent 70%,
          rgb(255, 255, 255) 69%
        );
      }
    }
  }
`
export const StyledTabs = styled(Tabs)`
  && {
    background-color: #ccf5fc;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`
export const StyledSmallTabs = styled(Tabs)`
  && {
    background-color: transparent !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
`

export const StyledSmallTab = styled(Tab)`
  && {
    width: ${({ amount }) => `${100 / amount}%`};

    /* min-width: 260px; */
    color: #988fb6;

    &.Mui-selected {
      position: relative;
      background-color: white;
      overflow: visible;
      .MuiTouchRipple-root {
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 10px;
        height: 10px;
        z-index: 1;
      }
      &::before {
        left: -10px;
        background: radial-gradient(
          circle at left top,
          transparent 70%,
          rgb(255, 255, 255) 69%
        );
      }
      &::after {
        right: -10px;
        background: radial-gradient(
          circle at right top,
          transparent 70%,
          rgb(255, 255, 255) 69%
        );
      }
    }
  }
`
