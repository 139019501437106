import React from "react"
import { Home, EmojiFoodBeverage, Group, Shield } from "@mui/icons-material"

export const benefits = [
  {
    name: "Znane, komfortowe miejsce dla seniora",
    description:
      "Twój bliski nie chce zmieniać miejsca zamieszkania. Własne, znane mieszkanie to jego azyl? Nasza opieka pozwala na świadczenie profesjonalnej opieki nad seniorami bez konieczności przeprowadzki. Dzięki opiece domowej Twój bliski będzie mógł zyskać bezpieczeństwo i wsparcie bez utraty komfortu jakim jest przebywanie w znanej i lubianej przestrzeni.",
    icon: <Home />,
  },
  {
    name: "Domowa atmosfera",
    description:
      "Biały kitel budzi strach w Twoim bliskim? Szpitalne wnętrza i medyczne uniformy generują niepotrzebny stres i napięcie u osób starszych.  Nasze opiekunki to przyjazne i wykwalifikowane osoby, które jednocześnie dbają o domową i przyjemną atmosferę. Nasz personel robi wszystko, aby swoją aparycją wzbudzać jak najwięcej zaufania. Opiekunka z Mediry zawsze będzie swobodnie ubrana i będzie kojarzyć się Twojemu bliskiemu z bezpieczeństwem i domem.",
    icon: <EmojiFoodBeverage />,
  },
  {
    name: "Indywidualne podejście",
    description:
      "Medira to nie korporacja. Mimo, że obsłużyliśmy już ponad 2000 seniorów wiemy, że indywidualne podejście jest niezbędne w tej profesji. Każdy nasz klient jest przez nas obsługiwany w indywidualny, dostosowany do jego potrzeb sposób. Rodzaj opieki, metody wprowadzenia opiekunki do domu seniora, zapoznanie z podopiecznym oraz zakres obowiązków ustalany jest indywidualnie. Nie istnieje bowiem jedno, złote rozwiązanie, które będzie odpowiednie dla każdej osoby starszej. Nasza usługa ma odpowiadać szczegółowym preferencjom danego seniora, dlatego od naszych klientów oczekujemy dostępu do pełnej informacji na temat podopiecznego: jego codziennej rutyny, zainteresowań, frustracji, przyzwyczajeń – tylko dzięki temu nasza opiekunka odpowie w 100 procentach na potrzeby seniora.",
    icon: <Group />,
  },
  {
    name: "Bezpieczeństwo podopiecznego",
    description:
      "Jesteśmy autorem specjalistycznych kursów dla opiekunów osób starszych, a nasza wiedza na temat opieki nas seniorami poparta jest 9 letnim doświadczeniem na rynku opiekuńczym w Polsce i Niemczech. Każda nasza opiekunka posiada certyfikat ukończenia kursu pierwszej pomocy, opieki nad chorymi na demencję, opieki nad chorymi na Alzheimera, opieki nad osobami leżącymi oraz opieki nad pacjentami chorymi na nowotwór.",
    icon: <Shield />,
  },
]
