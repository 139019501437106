import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Font42 from "components/Fonts/Font42"
import { Grid } from "@mui/material"
import Accordion from "components/Accordion/Accordion"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Hr from "components/Hr/Hr"
import { options } from "constants/richTextOptions"

const FAQSection = () => {
  const data = useStaticQuery(graphql`
    query ClientsFAQQuery {
      allContentfulStronaDlaKlienta {
        nodes {
          faqDlaKlienta {
            contentful_id
            answer {
              raw
            }
            question
          }
        }
      }
    }
  `)

  const qa = data.allContentfulStronaDlaKlienta.nodes[0].faqDlaKlienta

  const accordionData = qa.map(({ answer, question, contentful_id }) => ({
    question,
    answer: <>{renderRichText(answer, options)}</>,
    id: contentful_id,
  }))

  return (
    <section>
      <Grid>
        <Hr mb={4} mt={6} />
        <Font42>Najczęściej zadawane pytania</Font42>
        <Grid mt={5}>
          <Accordion accordionData={accordionData} />
        </Grid>
      </Grid>
    </section>
  )
}

export default FAQSection
