import React from "react"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import ApplicationForm from "./ApplicationForm/ApplicationForm"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import { Section } from "./ContactForm.styled"

const ContactForm = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Section id="kontakt">
      <div>
        <Font42 ta="center">Potrzebujesz opiekunki? Zgłoś się do nas!</Font42>
        <Font24 ta="center" fw="500" mb={"40px"}>
          Wypełnij krótki formularz lub zadzwoń do naszego koordynatora{" "}
          <a href="tel:+48602287747">+48 602-287-747</a>
        </Font24>

        <Grid container wrap={isMobile ? "wrap" : "nowrap"}>
          <Grid item xs={isMobile ? 12 : 8} sx={{ zIndex: 1 }}>
            <ApplicationForm />
          </Grid>
          <Grid
            item
            xs={isMobile ? 12 : 5}
            sx={{ ml: isMobile ? 0 : -2, mt: isMobile ? -2 : 0 }}
          >
            <StaticImage
              src="../../../images/application-form.png"
              width={520}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Formularz kontaktowy"
              placeholder="transparent"
              style={{ height: "100%", width: "100%" }}
            />
          </Grid>
        </Grid>
      </div>
    </Section>
  )
}

export default ContactForm
