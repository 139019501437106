import styled, { keyframes } from "styled-components"
import Font16 from "components/Fonts/Font16"

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (min-width: 780px) {
    flex-direction: row;
  }
`

export const HomeCareCard = styled.div`
  position: relative;
  box-shadow: ${({ $isHouseCare }) =>
    $isHouseCare
      ? "0px 6px 50px rgba(0, 0, 0, 0.3)"
      : "0px 6px 50px rgba(107, 198, 214, 0.3)"};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  z-index: ${({ $isHouseCare }) => !$isHouseCare && 2};

  @media only screen and (min-width: 780px) {
    transform: ${({ $isHouseCare }) => !$isHouseCare && "scale(1.05)"};
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  max-width: 400px;
  max-height: 150px;

  .gatsby-image-wrapper {
    max-height: 150px;
  }
  @media only screen and (min-width: 780px) {
    max-height: 215px;
    .gatsby-image-wrapper {
      max-height: 215px;
    }
  }
`

export const TitleWrapper = styled.div`
  position: absolute;
  inset: 0;
  background: ${({ $isHouseCare }) =>
    $isHouseCare ? "rgba(0, 0, 0, 0.25)" : "rgba(54, 212, 222, 0.4)"};

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ListWrapper = styled.ul`
  padding: 20px 25px;
  background: ${({ $isHouseCare }) => ($isHouseCare ? "#ECECEC" : "#f5feff")};
  list-style: none;
  margin-bottom: 0;
  p {
    color: ${({ $isHouseCare }) => $isHouseCare && "#757477"};
  }
`

export const ListElement = styled.li`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-left: 20px;
  &::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    background: ${({ $isHouseCare }) => ($isHouseCare ? "#757477" : "#544485")};
    border-radius: 50%;
    top: -4px;
    left: -15px;
  }
`

const showTop = keyframes`
  0% {
    opacity: 0;

    width: 0%;
  }
  3% {
    opacity: 1;
    width: 100%;
  }
  70% {
    width: 100%;
    opacity: 1

  }
   80% {
    opacity: 0;

  }
  100% {
    opacity: 0;
  }
`

const showRight = keyframes`

0% {
    opacity: 0;

    height: 0%;
}
  3% {
    opacity: 1;
    height: 0%;
  }
  6% {
    height: 100%;
  }
  70% {
    height: 100%;
    opacity: 1

  }
   80% {
    opacity: 0;

  }
  100% {
    opacity: 0;
  }
`

const showBottom = keyframes`
0% {
    opacity: 0;
    width: 0%;
}
  3% {
    opacity: 1;
    width: 0%;
  }
  6% {
    width: 0%;
  }
  9% {
    width: 100%;
  }
  70% {
    width: 100%;
    opacity: 1

  }
   80% {
    opacity: 0;

  }
  100% {
    opacity: 0;
  }
`

const showLeft = keyframes`
0% {
    opacity: 0;
    height: 0%;
}
  3% {
    height: 0%;
    opacity: 1
  }
  6% {
    height: 0%;
  }
  9% {
    height: 0%;
  }
  12% {
    height: 100%;
  }
  70% {
    height: 100%;
    opacity: 1

  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
 
`

const Bar = styled.div`
  position: absolute;
  background: #6bc6d6;
`

export const TopBar = styled(Bar)`
  opacity: 1;
  top: 0;
  left: 0;
  height: 5px;
  animation: ${showTop} 10s infinite;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
`

export const RightBar = styled(Bar)`
  top: 0;
  right: 0;
  width: 5px;
  animation: ${showRight} 10s infinite;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
`

export const BottomBar = styled(Bar)`
  bottom: 0;
  right: 0;
  height: 5px;
  animation: ${showBottom} 10s infinite;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
`

export const LeftBar = styled(Bar)`
  bottom: 0;
  left: 0;
  width: 5px;
  animation: ${showLeft} 10s infinite;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
`

export const CardsDivider = styled(Font16)`
  && {
    position: relative;
    font-style: italic;
    width: 100%;
    text-align: center;
    max-width: 400px;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 1px;
      width: 45%;
      background-color: #544485;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      height: 1px;
      width: 45%;
      background-color: #544485;
    }
  }
`
