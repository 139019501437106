import { yupResolver } from "@hookform/resolvers/yup"
import {
  Alert,
  Card,
  Grid,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import Button from "components/Buttons/Button"
import Link from "components/Buttons/Link"
import Checkbox from "components/Checkbox/Checkbox"
import Font12 from "components/Fonts/Font12"
import Font22 from "components/Fonts/Font22"
import Hr from "components/Hr/Hr"
import TextInput from "components/Inputs/TextInput"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import * as yup from "yup"
import { parseDateWithTime } from "../../../../shared/parseDate"

const ApplicationForm = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [successAlertOpen, setSuccessAlertOpen] = useState(false)
  const [errorAlertOpen, setErrorAlertOpen] = useState(false)

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const schema = yup
    .object({
      fullName: yup.string().required("Wprowadź swoje imię i nazwisko"),
      telephone: yup
        .string()
        .required("Wprowadź numer telefonu")
        .matches(
          phoneRegExp,
          "Wprowadź poprawny numer telefonu (+48999888777)"
        ),
      email: yup.string().email("Wprowadź poprawny adres email"),
      city: yup.string().required("Wprowadź miasto w którym szukasz opiekunki"),
      agreement: yup
        .bool()
        .required("Pole musi być zaznaczone")
        .oneOf([true], "Pole musi być zaznaczone"),
      careTypeHouse: yup
        .bool()
        .test(
          "either-careType",
          "Musisz wybrać co najmniej jedną opcję opieki",
          function (value, context) {
            const { careTypeHours } = context.parent
            return value || careTypeHours
          }
        ),
      careTypeHours: yup.bool(),
    })
    .required()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async (data, e) => {
    setIsFormLoading(true)
    const parsedDate = parseDateWithTime(new Date())

    const htmlContent = `
    <h3>Wiadomość wysłana z formularza kontaktowego <b>dla klientów</b> na stronie medira.pl</h3>
    <br/>
    <br/>
    <p><strong>Imię i nazwisko</strong>: <span>${data.fullName}</span></p>
    <p><strong>Email</strong>: <span>${data.email}</span></p>
    <p><strong>Telefon</strong>: <span>${data.telephone}</span></p>
    <p><strong>Miasto</strong>: <span>${data.city}</span></p>
    <p><strong>Opieka całodobowa</strong>: <span>${
      Boolean(data.careTypeHouse) ? "Tak" : "Nie"
    }</span></p>
    <p><strong>Opieka godzinowa</strong>: <span>${
      Boolean(data.careTypeHours) ? "Tak" : "Nie"
    }</span></p>
    <p><strong>Zgoda</strong>: <span>${
      Boolean(data.agreement)
        ? `Wyrażono zgody na przetwarzanie osobowych w formularzu osobowym na stronie internetowej www.medira.pl, ${parsedDate}, ${data.fullName}`
        : "Nie wyrażono zgody w formularzu"
    }</span></p>

    `

    try {
      const response = await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        body: JSON.stringify({
          subject: "Nowe zgłoszenie z formularza DLA KLIENTÓW",
          message: htmlContent,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (response.ok) {
        setSuccessAlertOpen(true)
        e.target.reset()
        reset({
          fullName: "",
          telephone: "",
          email: "",
          city: "",
          careTypeHours: false,
          careTypeHouse: false,
          agreement: false,
        })
      }
    } catch (error) {
      setErrorAlertOpen(true)
    } finally {
      setIsFormLoading(false)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    if (event === "error") {
      setErrorAlertOpen(false)
    }

    if (event === "success") {
      setSuccessAlertOpen(false)
    }
  }

  return (
    <Card variant={isMobile ? "mobile" : null}>
      <Font22>Dane kontaktowe</Font22>
      <Font12>
        Podstawowe informacje potrzebne w procesie poszukiwania opiekunki
      </Font12>
      <Hr noIndicator />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mb={2} mt={1}>
          <Grid item xs={12}>
            <Controller
              name="fullName"
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    {...field}
                    ref={null}
                    label="Imię i Nazwisko"
                    required
                    autocomplete="name"
                    disabled={isFormLoading}
                    error={Boolean(errors?.fullName)}
                    helperText={errors?.fullName?.message}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="telephone"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  ref={null}
                  label="Telefon"
                  type="tel"
                  required
                  disabled={isFormLoading}
                  error={Boolean(errors?.telephone)}
                  helperText={errors?.telephone?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  ref={null}
                  label="Email"
                  type="email"
                  error={Boolean(errors?.email)}
                  helperText={errors?.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  ref={null}
                  label="Miasto"
                  required
                  placeholder="np. Katowice"
                  type="city"
                  disabled={isFormLoading}
                  error={Boolean(errors?.city)}
                  helperText={errors?.city?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3} mt="-7px">
            <Font12 fw={900} mb={"3px"}>
              Typ opieki*
            </Font12>
            <Controller
              name="careTypeHouse"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  ref={null}
                  error={Boolean(errors?.careTypeHouse)}
                  helperText={errors?.careTypeHouse?.message}
                  checked={field.value ?? false}
                  disabled={isFormLoading}
                  label={<Font12 mb={0}>Opieka całodobowa</Font12>}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={3} mt="-10px">
            <Font12 Font12 fw={900} mb={"5px"} sx={{ color: "transparent" }}>
              Typ opieki
            </Font12>
            <Controller
              name="careTypeHours"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  ref={null}
                  error={Boolean(errors?.careTypeHours)}
                  helperText={errors?.careTypeHours?.message}
                  checked={field.value ?? false}
                  disabled={isFormLoading}
                  label={<Font12 mb={0}>Opieka godzinowa</Font12>}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="agreement"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  ref={null}
                  error={Boolean(errors?.agreement)}
                  helperText={errors?.agreement?.message}
                  checked={field.value ?? false}
                  disabled={isFormLoading}
                  label={
                    <Font12 mb={0}>
                      Zgoda na przetwarzanie danych i jej{" "}
                      <Link to="/zgody" underline>
                        treść
                      </Link>
                    </Font12>
                  }
                />
              )}
            />
          </Grid>

          <Grid item justifySelf={"end"} sx={{ ml: "auto" }}>
            <Button type="submit" disabled={isFormLoading}>
              {isFormLoading ? "Wysyłam..." : "Wyślij zgłoszenie"}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={successAlertOpen}
        autoHideDuration={5000}
        onClose={() => handleClose("success")}
        sx={{
          left: "50% !important",
          bottom: "50% !important",
          transform: "translate(-50%, -50%) !important",
        }}
      >
        <Alert
          onClose={() => handleClose("success")}
          severity="success"
          sx={{
            width: "100%",
            height: "100px",
            width: "400px",
            ".MuiAlert-message": {
              width: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Font22 style={{ width: "100%", textAlign: "center" }}>
            Wiadomość wysłana!
          </Font22>
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorAlertOpen}
        autoHideDuration={5000}
        onClose={() => handleClose("error")}
        sx={{
          left: "50% !important",
          bottom: "50% !important",
          transform: "translate(-50%, -50%) !important",
        }}
      >
        <Alert
          onClose={() => handleClose("error")}
          severity="error"
          sx={{
            width: "100%",
            height: "100px",
            width: "400px",
            ".MuiAlert-message": {
              width: "100%",
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Font22 style={{ width: "100%", textAlign: "center" }}>
            Ups... Coś poszło nie tak. Spróbuj ponownie.
          </Font22>
        </Alert>
      </Snackbar>
    </Card>
  )
}

export default ApplicationForm
