import styled from "styled-components"

export const Section = styled.section`
  padding: 40px 0 80px;
  margin-top: 100px;
  background-color: #faf8ff;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 98vw;
  margin-bottom: -150px;
  box-shadow: 0 0 0 1vw #faf8ff;
  & > div {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;

    @media only screen and (min-width: 1024px) {
      padding: 0;
    }
  }
`
