import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font24 from "components/Fonts/Font24"
import Font18 from "components/Fonts/Font18"
import { Card, Grid } from "@mui/material"
import { benefits } from "constants/forClientBenefits"

const Benefits = () => {
  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42 mb={6}>Korzyści płynące z opieki domowej</Font42>
      <Grid mb={10}>
        <Card>
          <Grid container spacing={6}>
            {benefits.map(({ name, description, icon }) => (
              <Grid item xs={12} md={6} key={name}>
                <Font24
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    svg: {
                      width: 40,
                      height: 40,
                    },
                  }}
                >
                  {icon}
                  <span style={{ display: "inline-block", marginLeft: "10px" }}>
                    {name}
                  </span>
                </Font24>
                <Font18 ta="justify">{description}</Font18>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </section>
  )
}

export default Benefits
