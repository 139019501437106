import React, { useRef, useState } from "react"
import Font42 from "components/Fonts/Font42"
import Font22 from "components/Fonts/Font22"
import Font18 from "components/Fonts/Font18"
import Hr from "components/Hr/Hr"
import EastIcon from "@mui/icons-material/East"
import { ElementWrapper, ImagesWrapper } from "./OurServices.styled"
import { useSpring, animated } from "react-spring"
import { useIsVisible } from "../../../hooks/useIsVisible"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useMediaQuery, useTheme } from "@mui/material"

const AnimatedItem = ({ children, delay, isVisible }) => {
  const [isRested, setIsRested] = useState(false)
  const props = useSpring({
    to: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translateX(0)" : "translateX(-20px)",
    },
    from: { opacity: 0, transform: "translateX(-20px)" },
    onRest: () => {
      setIsRested(true)
    },
    config: {
      duration: 500,
    },
    delay,
  })

  if (isRested) {
    return <ElementWrapper>{children}</ElementWrapper>
  }

  const AnimatedDiv = animated(ElementWrapper)

  return <AnimatedDiv style={props}>{children}</AnimatedDiv>
}
const AnimatedGatsbyImage = ({ isVisible, children, delay }) => {
  const [isRested, setIsRested] = useState(false)
  const props = useSpring({
    to: {
      opacity: isVisible ? 1 : 0,
      transform: isVisible ? "translateY(0)" : "translateY(10%)",
    },
    from: { opacity: 0, transform: "translateY(10%)" },
    onRest: () => {
      setIsRested(true)
    },
    delay,
    config: {
      tension: 80,
      friction: 15,
    },
  })

  if (isRested) {
    return <span>{children}</span>
  }

  return <animated.span style={props}>{children}</animated.span>
}

const data = [
  <Font18 ta="justify">
    <b>Stała opieka</b> z zamieszkaniem u seniora, lub <b>opieka godzinowa</b> –
    opiekunka odwiedza seniora na <b>kilka godzin dziennie</b>.
  </Font18>,
  <Font18 ta="justify">
    <b>Wsparcie w codziennych czynnościach</b> - pomoc w ubieraniu się,
    toalecie, przygotowywaniu posiłków, wizytach u lekarza i utrzymaniu porządku
    w domu.
  </Font18>,
  <Font18 ta="justify">
    <b>Troska o zdrowie</b> - pamiętamy o regularnym{" "}
    <b>zażywaniu leków, kontrolach lekarskich</b> i przestrzeganiu{" "}
    <b>dedykowanej diety</b>.
  </Font18>,
  <Font18 ta="justify">
    <b>Aktywność fizyczna i umysłowa</b> - organizujemy{" "}
    <b>
      spacery, gimnastykę, czytanie, gry logiczne, zajęcia sensoryczne i rozmowy
    </b>
    , aby utrzymać <b>witalność i zapobiec izolacji</b>.
  </Font18>,
  <Font18 ta="justify">
    <b>Wsparcie emocjonalne</b> - Nasza opiekunka nie tylko troszczy się o{" "}
    <b>aspekty fizyczne</b>, ale również o dobre <b>samopoczucie seniora</b>.
  </Font18>,
]

function OurServices() {
  const ref = useRef(null)
  const { isVisible } = useIsVisible(ref)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const itemsRef = useRef(null)
  const { isVisible: isVisibleItemsRef } = useIsVisible(itemsRef)

  const { forClient1, forClient2, forClient3 } = useStaticQuery(graphql`
    query {
      forClient1: file(name: { eq: "for-client-1" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 95
            placeholder: NONE
          )
        }
      }
      forClient2: file(name: { eq: "for-client-2" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 95
            placeholder: NONE
          )
        }
      }
      forClient3: file(name: { eq: "for-client-3" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 95
            placeholder: NONE
          )
        }
      }
    }
  `)

  const forClient1Image = getImage(forClient1.childImageSharp.gatsbyImageData)
  const forClient2Image = getImage(forClient2.childImageSharp.gatsbyImageData)
  const forClient3Image = getImage(forClient3.childImageSharp.gatsbyImageData)

  const images = isMobile
    ? [forClient2Image]
    : [forClient1Image, forClient2Image, forClient3Image]

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42>Nasza usługa opiekuńcza</Font42>
      <Font22 sx={{ fontWeight: 600 }}>
        Dlaczego opieka domowa to najlepszy wybór?
      </Font22>
      <Font18 sx={{ textAlign: "justify" }}>
        Wierzymy, że <b>każda osoba</b> zasługuje na dostęp do opieki na miarę
        swoich potrzeb, zachowując jednocześnie <b>poczucie niezależności</b> i
        komfortu swojego otoczenia. Nasza{" "}
        <b>wykwalifikowana i empatyczna opiekunka seniorów</b> zapewni Twemu
        bliskiemu to wszystko, co niezbędne do pełnego i{" "}
        <b>szczęśliwego życia</b>.
      </Font18>
      <Font18>
        <b>Opieka domowa Mediry to:</b>
      </Font18>
      <div ref={itemsRef}>
        {data.map((item, index) => (
          <AnimatedItem
            key={item}
            delay={index * 300}
            isVisible={isVisibleItemsRef}
          >
            <EastIcon />
            {item}
          </AnimatedItem>
        ))}
      </div>
      <ImagesWrapper ref={ref}>
        {images.map((image, index) => (
          <AnimatedGatsbyImage isVisible={isVisible} delay={index * 300}>
            <GatsbyImage image={image} alt="Opiekunka z podopieczną" />
          </AnimatedGatsbyImage>
        ))}
      </ImagesWrapper>
    </section>
  )
}

export default OurServices
