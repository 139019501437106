import React, { useRef, useState } from "react"
import { useSpring, animated } from "react-spring"
import { useIsVisible } from "../../../../hooks/useIsVisible"

function Number({ n }) {
  const [isRested, setIsRested] = useState(false)
  const ref = useRef(null)
  const { isVisible } = useIsVisible(ref)

  const { number } = useSpring({
    to: { number: isVisible ? n : 0 },
    from: { number: 0 },
    delay: isVisible ? 0 : 200,
    onRest: () => {
      setIsRested(true)
    },
    config: { mass: 1, tension: 20, friction: 10 },
  })

  if (isRested) {
    return <span>{n}</span>
  }
  return <animated.span ref={ref}>{number.to(n => n.toFixed(0))}</animated.span>
}

export default Number
