import React from "react"
import PropTypes from "prop-types"
import {
  Accordion as AccordionMui,
  AccordionDetails,
  AccordionSummary,
  Card,
} from "@mui/material"
import Font18 from "components/Fonts/Font18"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const Accordion = ({ accordionData }) => {
  return (
    <Card>
      {accordionData?.map(({ question, answer, id }) => {
        return (
          <AccordionMui key={id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Font18 fw={700}>{question}</Font18>
            </AccordionSummary>
            <AccordionDetails>{answer}</AccordionDetails>
          </AccordionMui>
        )
      })}
    </Card>
  )
}

Accordion.propTypes = {
  accordionData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
}

export default Accordion
