import React from "react"
import Font42 from "components/Fonts/Font42"
import { graphql, useStaticQuery } from "gatsby"
import { useMediaQuery, useTheme } from "@mui/material"
import OpinionCard from "../../Feedback/OpinionCard/OpinionCard"
import { OpinionWrapper, Section, StyledCarusel } from "./Opinions.styled"

const Opinions = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const { allContentfulStronaDlaKlienta } = useStaticQuery(graphql`
    query ClientOpinionsOptions {
      allContentfulStronaDlaKlienta {
        nodes {
          clientsOpinions {
            contentful_id
            name
            opinion {
              raw
            }
            surname
          }
        }
      }
    }
  `)

  const opinions = allContentfulStronaDlaKlienta.nodes[0].clientsOpinions
  return (
    <Section>
      <Font42 ta="center">Poznaj opinie naszych klientów</Font42>

      <StyledCarusel
        animation="slide"
        isMobile={isMobile}
        IndicatorIcon={<span />}
        navButtonsProps={{
          style: {
            backgroundColor: "transparent",
            color: "#544485",
            width: "45px",
            height: "45px",
          },
        }}
        indicatorIconButtonProps={{
          style: {
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "#3BB2C4",
            border: "1px solid #FFFFFF",
            boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
            margin: "0 3px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            backgroundColor: "white",
            border: "1px solid #2F8C9A",
            boxShadow: "none",
          },
        }}
        navButtonsAlwaysVisible
      >
        {opinions.map(opinion => (
          <OpinionWrapper>
            <OpinionCard {...opinion} width={isMobile && "70%"} />
          </OpinionWrapper>
        ))}
      </StyledCarusel>
    </Section>
  )
}

export default Opinions
