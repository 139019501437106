import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import { Card, Grid, useMediaQuery, useTheme } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { default as TabsComponent } from "components/Tabs/Tabs"
import { Icon, ImageWrapper } from "./styles"
import Font16 from "components/Fonts/Font16"
import Font24 from "components/Fonts/Font24"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { options } from "constants/richTextOptions"

const RecrutationSteps = () => {
  const query = useStaticQuery(graphql`
    query RecrutationSteps {
      allContentfulStronaDlaKlienta {
        nodes {
          etapPozyskaniaOpiekunki {
            title
            contentful_id
            order
            image {
              gatsbyImageData(
                backgroundColor: "#e5f5f7"
                quality: 100
                placeholder: NONE
                width: 300
              )
            }
            icon {
              icon
            }
            longDescription {
              raw
            }
          }
        }
      }
    }
  `)

  const steps =
    query.allContentfulStronaDlaKlienta.nodes[0].etapPozyskaniaOpiekunki

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("mobile"))

  const tabsData = steps
    .sort((a, b) => a.order - b.order)
    .map(tab => {
      const image = getImage(tab.image)

      return {
        title: (
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justifyContent={isMobile ? "center" : "flex-start"}
          >
            <Grid>
              <Icon
                dangerouslySetInnerHTML={{ __html: tab.icon.icon }}
                isMobile={isMobile}
              />
            </Grid>
            <Grid>
              <Font16
                fw={900}
                mb={0}
                sx={{ display: { xs: "none", mobile: "block" } }}
              >
                {tab.title}
              </Font16>
            </Grid>
          </Grid>
        ),
        content: (
          <Grid container spacing={4} justifyContent={"space-between"}>
            <Grid item xs={12} md={6}>
              <Font24 mb={5}>{tab.title}</Font24>
              {renderRichText(tab.longDescription, options)}
            </Grid>
            <ImageWrapper item xs={12} md={5} mt={{ xs: 0, md: 8 }}>
              <GatsbyImage image={image} alt={tab.title} />
            </ImageWrapper>
          </Grid>
        ),
        id: tab.contentful_id,
      }
    })

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42 mb={6}>Etapy pozyskania opiekunki</Font42>
      <Grid mb={10}>
        <Card>
          <Grid mx={"-35px"} my={"-35px"}>
            <TabsComponent tabsData={tabsData} />
          </Grid>
        </Card>
      </Grid>
    </section>
  )
}

export default RecrutationSteps
