import styled from "styled-components"
import Font56 from "components/Fonts/Font56"
import Font42 from "components/Fonts/Font42"
import Font18 from "components/Fonts/Font18"

export const Wrapper = styled.section`
  margin-top: 60px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 98vw;
  background-color: #faf8ff;
  padding: 30px 0;
  box-shadow: 0 0 0 1vw #faf8ff;
  & > div {
    max-width: 1200px;
    margin: 0 auto;
  }
`

export const Title = styled(Font42)`
  && {
    margin-bottom: 30px;
  }
`

export const NumbersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (min-width: 760px) {
    flex-direction: row;
    align-items: center;
  }
`

export const NumberElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  flex-basis: 25%;
  padding: 10px;
  flex-direction: column;

  @media only screen and (min-width: 760px) {
    min-height: 200px;
    border-right: 1px solid #e7e7e7;
    border-bottom: none;
    padding: 0 10px;
  }
  @media only screen and (min-width: 900px) {
    min-height: 165px;
  }

  &:nth-last-of-type(1) {
    border-right: none;
    border-bottom: none;
  }
`

export const StyledFont18 = styled(Font18)`
  && {
    padding: 0 10px;
    text-align: center;
  }
`
export const StyledFont56 = styled(Font56)`
  && {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
`
