import { Grid } from "@mui/material"
import styled from "styled-components"
import { theme } from "theme/theme"

export const Icon = styled.div`
  width: ${({ isMobile }) => (isMobile ? "100%" : "65px")};
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.palette.primary.main};
  svg {
    fill: ${theme.palette.primary.main};

    width: ${({ isMobile }) => (isMobile ? "100%" : "initial")};

    path {
      fill: ${theme.palette.primary.main};
    }
  }
`

export const ImageWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper-constrained {
    max-height: 370px;
  }
`
