import React, { useEffect, useState } from "react"
import { Card, Grid, useMediaQuery } from "@mui/material"
import ClientApplicationForm from "containers/ForClients/ContactForm/ApplicationForm/ApplicationForm"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { DialogWrapper, ImageWrapper, StyledDialogContent } from "./style"
import Font42 from "components/Fonts/Font42"
import Font32 from "components/Fonts/Font32"
import Font24 from "components/Fonts/Font24"
import Font18 from "components/Fonts/Font18"
import CloseIcon from "@mui/icons-material/Close"
import ButtonRaw from "components/Buttons/ButtonRaw"
import { useTheme } from "@mui/system"

const ClientApplicationFormModal = () => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 5000)
  }, [])

  const data = useStaticQuery(graphql`
    query {
      image: file(name: { eq: "application-form" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "#e5f5f7"
            quality: 90
            placeholder: NONE
          )
        }
      }
    }
  `)

  const image = getImage(data.image.childImageSharp.gatsbyImageData)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const bigText = "Potrzebujesz opiekunki? Zgłoś się do nas!"
  const smallText = (
    <>
      Wypełnij krótki formularz lub zadzwoń do naszego koordynatora{" "}
      <a href="tel:+48602287747">+48 602-287-747</a>
    </>
  )

  return (
    <DialogWrapper
      onClose={handleClose}
      open={open}
      maxWidth="lg"
      mobile={isMobile.toString()}
    >
      <StyledDialogContent>
        <Card>
          <ButtonRaw
            sx={{
              position: "absolute",
              top: { xs: 10, md: 30 },
              right: { xs: 10, md: 30 },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </ButtonRaw>
          <Grid
            my={5}
            mb={{ xs: 2, md: 3 }}
            container
            direction={"column"}
            alignItems={"center"}
          >
            {isMobile ? <Font32>{bigText}</Font32> : <Font42>{bigText}</Font42>}
            {isMobile ? (
              <Font18 textAlign="center" mb={0}>
                {smallText}
              </Font18>
            ) : (
              <Font24 fw={400} mb={0}>
                {smallText}
              </Font24>
            )}
          </Grid>
          <Grid
            container
            justifyContent="center"
            sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
          >
            <Grid item xs={12} md={8} lg={7} style={{ zIndex: 1 }}>
              <ClientApplicationForm />
            </Grid>
            <ImageWrapper
              item
              ml={{ xs: 0, md: -1 }}
              mt={{ xs: -2, md: 0 }}
              xs={12}
              md={3}
              lg={4}
            >
              <GatsbyImage image={image} alt="Rekrutacja" />
            </ImageWrapper>
          </Grid>
        </Card>
      </StyledDialogContent>
    </DialogWrapper>
  )
}

export default ClientApplicationFormModal
