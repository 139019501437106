import React from "react"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import Font32 from "components/Fonts/Font32"
import Font16 from "components/Fonts/Font16"
import { graphql, useStaticQuery } from "gatsby"
import { useMediaQuery, useTheme } from "@mui/material"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  BottomBar,
  CardsDivider,
  CardsWrapper,
  HomeCareCard,
  ImageWrapper,
  LeftBar,
  ListElement,
  ListWrapper,
  RightBar,
  TitleWrapper,
  TopBar,
} from "./HomeCareVSHouseCare.styled"
import {
  careHouseOptions,
  homeCareOptions,
} from "../../../constants/homeCareVSHouseCare"

const HomeCareVSHouseCare = () => {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("780"))

  const { homeCare, careHouse } = useStaticQuery(graphql`
    query {
      homeCare: file(name: { eq: "home-care" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 90
            placeholder: NONE
          )
        }
      }
      careHouse: file(name: { eq: "care-house" }) {
        childImageSharp {
          gatsbyImageData(
            backgroundColor: "transparent"
            quality: 90
            placeholder: NONE
          )
        }
      }
    }
  `)

  const homeCareImage = getImage(homeCare.childImageSharp.gatsbyImageData)
  const careHouseImage = getImage(careHouse.childImageSharp.gatsbyImageData)

  const cards = [
    {
      title: "Opieka domowa",
      options: homeCareOptions,
      image: homeCareImage,
      isCareHouse: false,
    },
    {
      title: "Dom opieki",
      options: careHouseOptions,
      image: careHouseImage,
      isCareHouse: true,
    },
  ]

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42 mb={6}>
        Dlaczego opieka domowa to lepsze rozwiązanie od domu opieki?
      </Font42>
      <CardsWrapper>
        {cards.map(({ title, options, isCareHouse, image }, index) => (
          <>
            <HomeCareCard key={title} $isHouseCare={isCareHouse}>
              <ImageWrapper>
                <GatsbyImage image={image} alt={title} />
                <TitleWrapper $isHouseCare={isCareHouse}>
                  <Font32 mt={"40px"} color="white">
                    {title}
                  </Font32>
                </TitleWrapper>
              </ImageWrapper>
              <ListWrapper $isHouseCare={isCareHouse}>
                {options.map(option => (
                  <ListElement key={option} $isHouseCare={isCareHouse}>
                    <Font16
                      ta="justify"
                      mb={"10px"}
                      fw={isCareHouse ? 500 : 700}
                    >
                      {option}
                    </Font16>
                  </ListElement>
                ))}
              </ListWrapper>
              {!isCareHouse && (
                <>
                  <TopBar />
                  <RightBar />
                  <BottomBar />
                  <LeftBar />
                </>
              )}
            </HomeCareCard>
            {index === 0 && isMobile && <CardsDivider>vs</CardsDivider>}
          </>
        ))}
      </CardsWrapper>
    </section>
  )
}

export default HomeCareVSHouseCare
