import React from "react"
import { Grid } from "@mui/material"
import { StyledTab, StyledTabs, Wrapper } from "./styles"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid sx={{ padding: { xs: "20px", sm: "50px" } }}>{children}</Grid>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Tabs = ({ tabsData }) => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Wrapper>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        {tabsData.map((item, i) => (
          <StyledTab
            key={item.id}
            label={item.title}
            {...a11yProps(i)}
            amount={tabsData.length}
          />
        ))}
      </StyledTabs>
      {tabsData.map((item, i) => (
        <TabPanel key={item.id} value={value} index={i}>
          {item.content}
        </TabPanel>
      ))}
    </Wrapper>
  )
}

export default Tabs
