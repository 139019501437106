import React from "react"
import Number from "./components/Number"
import {
  NumberElement,
  NumbersWrapper,
  StyledFont18,
  StyledFont56,
  Title,
  Wrapper,
} from "./MediraInNumber.styled"
import { companyData } from "../../../constants/companyData"

function MediraInNumbers() {
  const nowYear = new Date().getFullYear()
  const experienceYears = nowYear - companyData.mediraFoundedYear

  const numbersData = [
    {
      title: "lat doświadczenia w opiece nad seniorami w Niemczech i Polsce",
      target_number: experienceYears,
      value: 0,
      id: 0,
    },
    {
      title: "zadowolonych klientów w Niemczech i Polsce",
      target_number: 2000,
      value: 0,
      id: 1,
    },
    {
      title: "zatrudnionych opiekunów osób starszych",
      target_number: 3000,
      value: 0,
      id: 2,
    },
    {
      title:
        "uczestników naszych autorskich kursów dla opiekunów osób starszych",
      target_number: 100,
      value: 0,
      id: 3,
    },
  ]
  return (
    <Wrapper>
      <div>
        <Title ta="center">Medira w liczbach</Title>
        <NumbersWrapper>
          {numbersData.map(counter => (
            <NumberElement>
              <StyledFont56>
                +<Number n={counter.target_number} />
              </StyledFont56>
              <StyledFont18>{counter.title}</StyledFont18>
            </NumberElement>
          ))}
        </NumbersWrapper>
      </div>
    </Wrapper>
  )
}

export default MediraInNumbers
