import styled from "styled-components"

export const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d6d6d6;
  padding: 15px 0;
  &:nth-last-of-type(1) {
    border-bottom: 1px solid transparent;
  }
  svg {
    color: #544485;
    margin-right: 10px;
  }
  p {
    margin-bottom: 0;
  }
`

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  margin: 20px auto 0;
  @media only screen and (min-width: 900px) {
    justify-content: space-between;
  }
`
