import Carousel from "react-material-ui-carousel"
import styled from "styled-components"

export const Section = styled.section`
  margin-bottom: 75px;
`

export const StyledCarusel = styled(Carousel)`
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  @media only screen and (min-width: 1024px) {
    width: 70%;
  }
`

export const OpinionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 700px;
  @media only screen and (min-width: 640px) {
    min-height: 550px;
  }
  @media only screen and (min-width: 1024px) {
    min-height: 500px;
  }
  @media only screen and (min-width: 1200px) {
    min-height: 450px;
  }
`
