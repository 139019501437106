import React from "react"
import Font42 from "components/Fonts/Font42"
import Hr from "components/Hr/Hr"
import { StyledFont18 } from "./WhyTrustUs.styled"
import { companyData } from "../../../constants/companyData"

function WhyTrustUs() {
  const nowYear = new Date().getFullYear()
  const experienceYears = nowYear - companyData.mediraFoundedYear

  return (
    <section>
      <Hr mb={4} mt={6} />
      <Font42>Medira – Jesteśmy gwarancją bezpieczeństwa.</Font42>
      <StyledFont18 ta="justify">
        <b>Medira</b> to dynamicznie rozwijająca się firma, która dedykowanej{" "}
        <b>opiece senioralnej</b> nadaje nowe znaczenie. Naszym priorytetem jest
        zapewnienie <b>najbezpieczniejszego i najprzyjemniejszego życia</b>{" "}
        seniorom, umożliwiając im aktywne i godne starzenie się w swoim
        otoczeniu.
      </StyledFont18>
      <StyledFont18>
        <b>DLACZEGO MEDIRA?</b> Nasza firma łączy w sobie doświadczenie, empatię
        oraz profesjonalizm, tworząc opiekę opartą na indywidualnych potrzebach
        każdego seniora. Medira to <b>{experienceYears} lat doświadczenia</b> w
        pracy z seniorami w miejscach ich zamieszkania.
      </StyledFont18>
      <StyledFont18>
        Nasi <b>wykwalifikowani opiekunowie</b>, z troską i szacunkiem,
        zapewniają wsparcie w codziennych czynnościach, opiece medycznej oraz
        wsparcie psychiczne. Wszystkie nasze opiekunki wyróżnia{" "}
        <b>empatia oraz profesjonalne przeszkolenie</b> z zakresu opieki
        senioralnej. A nasza firma jest również autorem{" "}
        <b>specjalistycznych kursów</b> dla opiekunów osób starszych.
      </StyledFont18>
    </section>
  )
}

export default WhyTrustUs
