export const homeCareOptions = [
  "Personalizowana usługa, dostosowana do indywidualnych preferencji seniora",
  "Znane i komfortowe środowisko – opiekujemy się seniorami w ich domach",
  "Indywidualny harmonogram opieki dostosowany do przyzwyczajeń i rutyny seniora",
  "Elastyczna forma współpracy – godzinowa lub z zamieszkaniem",
  "Domowa atmosfera, przyjazna opiekunka",
  "Wykwalifikowana opiekunka z certyfikatami ukończonych kursów",
  "Możliwość odwiedzenia swojego bliskiego o każdej godzinie i kontroli jego dobrostanu",
]
export const careHouseOptions = [
  "Ogromna liczba pacjentów, brak indywidualnego podejścia do potrzeb seniora",
  "Szpitalne, minimalistyczne wnętrze wzbudzające lęk i dyskomfort u seniora",
  "Brak pełnej prywatności ze względu na częstą konieczność dzielenia pokoju z innym chorym",
  "Ścisły harmonogram dnia, zaburzający dotychczasową rutynę i przyzwyczajenia seniora",
  "Poczucie izolacji i pozostawienia przez rodzinę",
  "Ograniczona autonomia ze względu na procedury domu opieki",
  "Statystycznie wzmożone ryzyko depresji i apatii u chorych oddanych do domu opieki",
  "Brak możliwości odwiedzenia seniora o każdej porze dnia i kontroli jego dobrostanu, odwiedziny najczęściej odbywają się w wyłącznie określone dni i godziny",
]
