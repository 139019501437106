import { Dialog, DialogContent, Grid } from "@mui/material"
import styled from "styled-components"

export const DialogWrapper = styled(Dialog)`
  .MuiPaper-root {
    overflow: ${({ mobile }) => (mobile === "true" ? "auto" : "hidden")};
  }
`
export const StyledDialogContent = styled(DialogContent)`
  padding: 0 !important;
  & > div:first-child {
    padding: 30px 5px;
  }
`
export const ImageWrapper = styled(Grid)`
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`
